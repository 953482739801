import {
    IonBadge,
    IonButton,
    IonButtons, IonCard, IonCardContent, IonCardHeader,
    IonContent,
    IonFooter,
    IonHeader, IonIcon,
    IonInput,
    IonItem, IonItemOption, IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList, IonListHeader,
    IonModal,
    IonNote,
    IonPage, IonReorder, IonReorderGroup,
    IonText,
    IonTitle,
    IonToolbar, useIonAlert
} from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './ExistingTimers.css';
import {useEffect, useState} from "react";
import {ITimer, ITimerItem} from "../components/Interfaces";
import {pizza, time} from "ionicons/icons";
// @ts-ignore
import { useSpeechSynthesis } from 'react-speech-kit';

function AddTimerModal ({closeModalHandler, showModal, selectedTimerId}: any) {
    const closeHandler = () => {
        clearModalValues();
        closeModalHandler();
    }
    
    const clearModalValues = () => {
        setTimerId(undefined);
        setTimerTitle(undefined);
        setDuration(undefined);
        setSpeech(undefined);
        setTimeSegments([])
    }
    
    const [timeSegment, setTimeSegment] = useState<ITimerItem>();
    const [speech, setSpeech] = useState<string>();
    const [timerTitle, setTimerTitle] = useState<string>();
    const [duration, setDuration] = useState<number>();
    const [timerId, setTimerId] = useState<string>();
    const [timeSegments, setTimeSegments] = useState<ITimerItem[]>([]);
    const [present, dismiss] = useIonAlert();

    useEffect(() => {
        const storageTimers = localStorage.getItem("timers") || undefined;
        const timersArray = storageTimers ? JSON.parse(storageTimers) : null;

        if (!selectedTimerId){
            setTimerId(generate_id());
            return;
        }

        const timer = timersArray.find((x: ITimer) => x.id === selectedTimerId);

        if (timer){
            setTimerId(timer.id);
            setTimeSegments(timer.segments);
            setTimerTitle(timer.name);
        }
    }, [selectedTimerId])

    const generate_id = (): string => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    const saveTimer = (): void => {
        if (!speech || !duration) {
            present({
                header: 'Oops',
                message: 'You must fill out all fields to save a timer segment.',
                buttons: [
                    'Cancel',
                    {text: 'Ok', handler: (d) => console.log('ok pressed')},
                ],
                onDidDismiss: (e) => console.log('did dismiss'),
            })
            return;
        }

        const newSegment: ITimerItem = {
            speech: speech,
            duration: duration
        }

        setTimeSegments([...timeSegments, newSegment])

        setSpeech(undefined);
        setDuration(undefined);
        // setSound(undefined);
    }
    
    const submitTimerModal = () => {
        persistTimer();
        clearModalValues();
        closeModalHandler();
    }

    const doReorder = (event: CustomEvent<any>) => {
        setTimeSegments(event.detail.complete(timeSegments));
    }

    const persistTimer = () => {
        if (!timerId){
            return;
        }
        
        const timer: ITimer = {
            id: timerId,
            name: timerTitle || "unnamed",
            segments: timeSegments
        }

        const storageTimers = localStorage.getItem("timers") || undefined;
        const timersArray = storageTimers ? JSON.parse(storageTimers) : [];

        // @ts-ignore
        var existingTimerIndex = timersArray.findIndex((x: ITimer) => x.id === timer.id);
        if (existingTimerIndex != -1){
            // @ts-ignore
            timersArray[existingTimerIndex] = timer;
        }
        else {
            // @ts-ignore
            timersArray.push(timer);
        }

        // @ts-ignore
        localStorage.setItem("timers", JSON.stringify(timersArray));
    }
    
    const removeSegment = (segmentIndex: number) => {
        setTimeSegments(timeSegments.filter((x: ITimerItem, i) => i !== segmentIndex));
    }
    
    const duplicateSegment = (segmentIndex: number) => {
        setTimeSegments([...timeSegments, timeSegments[segmentIndex]]);
    }
    
    const { speak } = useSpeechSynthesis();

    return <IonModal isOpen={showModal} cssClass={'my-custom-class'}>
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>New Timer</IonTitle>
                <IonButtons slot="end">
                    <IonButton type="button" onClick={() => closeHandler()}>cancel</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <IonHeader collapse="condense">
                <IonToolbar>
                    <IonTitle size="large">New Timer</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonCard>
                <IonCardHeader>Timer Properties</IonCardHeader>
                <IonCardContent>
                    <IonItem>
                        <IonLabel position="stacked">Timer Title</IonLabel>
                        <IonInput placeholder="Enter Title" value={timerTitle} onIonInput={(e: any) => setTimerTitle(e.target.value)}/>
                    </IonItem>
                </IonCardContent>
            </IonCard>
            <IonCard>
                <IonCardHeader>Segment Properties</IonCardHeader>
                <IonCardContent>
                    <IonItem>
                        <IonLabel position="stacked">Segment Speech</IonLabel>
                        <IonInput placeholder="Enter Segment Speech" value={speech} onIonInput={(e: any) => setSpeech(e.target.value)}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Duration (in seconds)</IonLabel>
                        <IonInput value={duration} type="number" placeholder="Enter Duration"
                                  onIonInput={(e: any) => setDuration(e.target.value)}/>
                    </IonItem>
                    {/*<IonItem>*/}
                    {/*    <IonLabel position="stacked">Sound</IonLabel>*/}
                    {/*    <IonSelect value={sound} placeholder="Select Sound"*/}
                    {/*               onIonChange={e => setSound(e.detail.value)}>*/}
                    {/*        <IonSelectOption value="readyToPump">Get Ready To Pump</IonSelectOption>*/}
                    {/*        <IonSelectOption value="pump">Pump You UP</IonSelectOption>*/}
                    {/*        <IonSelectOption value="rest">Take a break</IonSelectOption>*/}
                    {/*    </IonSelect>*/}
                    {/*</IonItem>*/}
                </IonCardContent>
                <IonButton expand="full" onClick={saveTimer}>Add Segment</IonButton>
            </IonCard>
            <IonCard>
                <IonCardHeader>{timerTitle}</IonCardHeader>
                <IonCardContent>
                    <IonListHeader>Time Segments</IonListHeader>
                    <IonList>
                        <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                            {
                                timeSegments && timeSegments.map((item, index) => {
                                    return (
                                        <IonItemSliding key={index}>
                                            <IonItem key={item.speech + index}>
                                                <IonLabel className="ion-text-wrap">
                                                    <h2>{item.speech}</h2>
                                                    {/*<IonNote>{item.sound}</IonNote>*/}
                                                </IonLabel>
                                                <IonBadge>{item.duration} seconds</IonBadge>
                                                <IonReorder slot="start"><IonIcon icon={pizza} /></IonReorder>
                                            </IonItem>
                                            <IonItemOptions side="start">
                                                <IonItemOption color="primary" onClick={() => duplicateSegment(index)}>Duplicate</IonItemOption>
                                            </IonItemOptions>
                                            <IonItemOptions side="end">
                                                <IonItemOption color="danger" onClick={() => removeSegment(index)}>Remove</IonItemOption>
                                            </IonItemOptions>
                                        </IonItemSliding>
                                    )
                                })
                            }
                        </IonReorderGroup>
                    </IonList>
                </IonCardContent>
            </IonCard>
        </IonContent>
        <IonFooter>
            <IonButton expand="full" onClick={submitTimerModal}>Save Timer</IonButton>
        </IonFooter>
    </IonModal>
}
const ExistingTimers: React.FC = () => {
    const [showNewTimerModal, setShowNewTimerModal] = useState<boolean>(false);
    const [timers, setTimers] = useState<ITimer[]>([]);
    const [selectedTimerId, setSelectedTimerId] = useState<string>();
    
    // useEffect(() => {
    //     if (selectedTimerId){
    //         setShowNewTimerModal(true);
    //     }
    // }, [selectedTimerId])

    
    useEffect(() => {
        getTimers();
    }, [])
    
    useEffect(() => {
    }, [timers])
    
    const getTimers = () => {
        const storageTimers = localStorage.getItem("timers") || undefined;
        const timersArray = storageTimers ? JSON.parse(storageTimers) : [];
        setTimers(timersArray);
    }
    
    const closeModalHandler = () => {
        setShowNewTimerModal(false);
        getTimers();
    }
    
    const editTimer = (timerId: string) => {
        setSelectedTimerId(timerId);
        setShowNewTimerModal(true);
        
    }
    
    const removeTimer = (timerId: string) => {
        const newTimers = timers.filter(x => x.id !== timerId);
        setTimers(newTimers);
        localStorage.setItem("timers", JSON.stringify(newTimers));
    }
    
    const { speak } = useSpeechSynthesis();
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>My Timers</IonTitle>
                    <IonButtons slot="end">
                        <IonButton type="button" onClick={() => setShowNewTimerModal(true)}>New</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <AddTimerModal selectedTimerId={selectedTimerId} showModal={showNewTimerModal} closeModalHandler={closeModalHandler}/>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">My Timers</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonList>
                    {
                        timers && timers.map((timer, index) => {
                                return (
                                    <IonItemSliding key={index}>
                                        <IonItem key={timer.name + index}>
                                            <IonLabel className="ion-text-wrap">
                                                <h2>{timer.name}</h2>
                                                {/*<IonNote>{item.sound}</IonNote>*/}
                                                {/*<p>{timer.segments && timer.segments.length} segments : {timer && timer.segments && timer.segments.reduce((prev: any, cur: any) =>  { return (prev.duration) + (cur.duration)})}</p>*/}
                                            </IonLabel>
                                            <IonButton routerLink={`runtimer/${timer.id}`}>Run</IonButton>
                                            {/*<IonBadge>{timer.duration} seconds</IonBadge>*/}
                                            <IonReorder slot="start"><IonIcon icon={pizza} /></IonReorder>
                                        </IonItem>
                                        <IonItemOptions side="start">
                                            <IonItemOption color="primary" onClick={() => {editTimer(timer.id)}}>Edit</IonItemOption>
                                        </IonItemOptions>
                                        <IonItemOptions side="end">
                                            <IonItemOption color="danger" onClick={() => {removeTimer(timer.id)}}>Remove</IonItemOption>
                                        </IonItemOptions>
                                    </IonItemSliding>
                                )
                            }
                        )
                    }
                </IonList>
            </IonContent>
            <IonFooter>
                <IonNote>Created by Jacob Hunt | copyright 2021</IonNote>
            </IonFooter>
        </IonPage>
    );
};

export default ExistingTimers;
