import {
    generateId,
    IonBadge,
    IonButton,
    IonCard, IonCardContent, IonCardHeader, IonContent, IonHeader,
    IonIcon,
    IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel,
    IonList, IonListHeader, IonNote, IonPage,
    IonReorder,
    IonReorderGroup, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonAlert
} from '@ionic/react';
import { pizza } from 'ionicons/icons';
import ExploreContainer from '../components/ExploreContainer';
import './NewTimer.css';
import {useEffect, useState} from "react";
import {time} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import {ITimerItem, ITimer} from "../components/Interfaces";

enum Sound {
    pump,
    readyToPump,
    rest
}

interface NewTimerPageProps extends RouteComponentProps<{id: string;}> {}
const NewTimer: React.FC<NewTimerPageProps> = ({match}) => {
    const [timeSegment, setTimeSegment] = useState<ITimerItem>();
    const [speech, setSpeech] = useState<string>();
    const [timerTitle, setTimerTitle] = useState<string>();
    const [duration, setDuration] = useState<number>();
    const [timerId, setTimerId] = useState<string>("");
    const [timeSegments, setTimeSegments] = useState<ITimerItem[]>([]);
    const [present, dismiss] = useIonAlert();
    // const [timersArray, setTimersArray] = useState<ITimer[]>([]);
    
    useEffect(() => {
        if (timerId){
            persistTimer();
        }
    }, [timeSegments])
    
    useEffect(() => {
        const storageTimers = localStorage.getItem("timers") || undefined;
        const timersArray = storageTimers ? JSON.parse(storageTimers) : null;
        // setTimersArray(timersArray);
        
        if (!match || !match.params.id){
            setTimerId(generate_id());
            return;
        }
        
        const timer = timersArray.find((x: ITimer) => x.id === match.params.id);
        
        if (timer){
            setTimerId(timer.id);
            setTimeSegments(timer.segments);
            setTimerTitle(timer.name);
        }
    }, [])

    const generate_id = (): string => {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
    
    const saveTimer = (): void => {
        if (!speech || !duration) {
            present({
                header: 'Oops',
                message: 'You must fill out all fields to save a timer segment.',
                buttons: [
                    'Cancel',
                    {text: 'Ok', handler: (d) => console.log('ok pressed')},
                ],
                onDidDismiss: (e) => console.log('did dismiss'),
            })
            return;
        }
        
        const newSegment: ITimerItem = {
            speech: speech,
            duration: duration
        }

        setTimeSegments([...timeSegments, newSegment])

        setSpeech(undefined);
        setDuration(undefined);
        // setSound(undefined);
    }
    
    const doReorder = (event: CustomEvent<any>) => {
        setTimeSegments(event.detail.complete(timeSegments));
    }
    
    const persistTimer = () => {
        const timer: ITimer = {
            id: timerId,
            name: timerTitle || "unnamed",
            segments: timeSegments
        }

        const storageTimers = localStorage.getItem("timers") || undefined;
        const timersArray = storageTimers ? JSON.parse(storageTimers) : [];
        
        // @ts-ignore
        var existingTimerIndex = timersArray.findIndex((x: ITimer) => x.id === timer.id);
       if (existingTimerIndex != -1){
           // @ts-ignore
           timersArray[existingTimerIndex] = timer;
       }
       else {
           // @ts-ignore
           timersArray.push(timer);
       }
        
        // @ts-ignore
        localStorage.setItem("timers", JSON.stringify(timersArray));
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>New Timer</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">New Timer</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                    <IonCardHeader>Timer Properties</IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel position="stacked">Timer Title</IonLabel>
                            <IonInput placeholder="Enter Title" value={timerTitle} onIonInput={(e: any) => setTimerTitle(e.target.value)}/>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>Segment Properties</IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel position="stacked">Segment Speech</IonLabel>
                            <IonInput placeholder="Enter Segment Speech" value={speech} onIonInput={(e: any) => setSpeech(e.target.value)}/>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Duration (in seconds)</IonLabel>
                            <IonInput value={duration} type="number" placeholder="Enter Duration"
                                      onIonInput={(e: any) => setDuration(e.target.value)}/>
                        </IonItem>
                        {/*<IonItem>*/}
                        {/*    <IonLabel position="stacked">Sound</IonLabel>*/}
                        {/*    <IonSelect value={sound} placeholder="Select Sound"*/}
                        {/*               onIonChange={e => setSound(e.detail.value)}>*/}
                        {/*        <IonSelectOption value="readyToPump">Get Ready To Pump</IonSelectOption>*/}
                        {/*        <IonSelectOption value="pump">Pump You UP</IonSelectOption>*/}
                        {/*        <IonSelectOption value="rest">Take a break</IonSelectOption>*/}
                        {/*    </IonSelect>*/}
                        {/*</IonItem>*/}
                    </IonCardContent>
                    <IonButton expand="full" onClick={saveTimer}>Submit</IonButton>
                </IonCard>
                <IonCard>
                    <IonCardHeader>{timerTitle}</IonCardHeader>
                    <IonCardContent>
                        <IonListHeader>Time Segments</IonListHeader>
                            <IonList>
                                <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                                {
                                    timeSegments && timeSegments.map((item, index) => {
                                        return (
                                            <IonItemSliding key={index}>
                                                <IonItem key={item.speech + index}>
                                                    <IonLabel className="ion-text-wrap">
                                                        <h2>{item.speech}</h2>
                                                        {/*<IonNote>{item.sound}</IonNote>*/}
                                                    </IonLabel>
                                                    <IonBadge>{item.duration} seconds</IonBadge>
                                                    <IonReorder slot="start"><IonIcon icon={pizza} /></IonReorder>
                                                </IonItem>
                                                <IonItemOptions side="end">
                                                    <IonItemOption color="danger" onClick={() => {}}>Remove</IonItemOption>
                                                </IonItemOptions>
                                            </IonItemSliding>
                                        )
                                    })
                                }
                                </IonReorderGroup>
                            </IonList>    
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default NewTimer;
