import {
    IonBackButton, IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonFooter,
    IonHeader, IonIcon, IonItem, IonItemOption, IonItemOptions,
    IonItemSliding, IonLabel,
    IonPage, IonReorder,
    IonTitle,
    IonToolbar,
    useIonRouter
} from '@ionic/react';
import './RunTimer.css';
import {RouteComponentProps} from "react-router";
import {useEffect, useState} from "react";
import {ITimer, ITimerItem} from "../components/Interfaces";
// @ts-ignore
import {useSpeechSynthesis} from 'react-speech-kit';
import { useWakeLock } from 'react-screen-wake-lock';
import {pizza} from "ionicons/icons";

interface NewTimerPageProps extends RouteComponentProps<{ id: string; }> {
}

const RunTimer: React.FC<NewTimerPageProps> = ({match}) => {
    const [timer, setTimer] = useState<ITimer>();
    const [showStartButton, setShowStartButton] = useState<boolean>(true);
    const [activeSegment, setActiveSegment] = useState<ITimerItem>();
    const [timeDisplay, setTimeDisplay] = useState<number>();
    const [intervalId, setIntervalId] = useState<number>();
    const router = useIonRouter();

    const { isSupported, released, request, release } = useWakeLock();
    
    useEffect(() => {
        const storageTimers = localStorage.getItem("timers") || undefined;
        const timersArray = storageTimers ? JSON.parse(storageTimers) : null;

        const thisTimer = timersArray.find((x: ITimer) => x.id === match.params.id);
        setTimer(thisTimer);
    }, [])

    const startTimerHandler = () => {
        if (!timer) {
            return;
        }

        startTimer(timer);
        setShowStartButton(false);
    }

    const {speak} = useSpeechSynthesis();

    const startTimer = (timersArray: ITimer) => {
        if (!timersArray.segments) {
            return;
        }
        
        // Request the screen to not turn off
        if (isSupported){
            request();
        }
        

        const runTimer = (timerSegment: ITimerItem, index: number) => {
            setActiveSegment(timerSegment);

            speak({text: timerSegment.speech});

            var remainingSeconds = timerSegment.duration;

            // This is where you will announce the speech part of the timer

            const intervalIdLocal: any = setInterval(() => {

                setIntervalId(intervalIdLocal);
                setTimeDisplay(remainingSeconds);

                if (remainingSeconds === 0) {
                    clearInterval(intervalIdLocal);
                    // Run the next segment if available
                    if (timersArray.segments[index + 1]) {
                        runTimer(timersArray.segments[index + 1], index + 1);
                    } else {
                        runTimer(timersArray.segments[0], 0);
                    }
                }

                remainingSeconds--;
            }, 1000);
        }

        const timerIndex = 0;
        runTimer(timersArray.segments[timerIndex], timerIndex);
    }

    const closeRun = () => {
        if (intervalId) {
            clearInterval(intervalId);
        }
        release();
        router.push('/', 'back', 'replace')
    }

    const stopTimer = () => {
        if (intervalId) {
            clearInterval(intervalId);
        }
        setShowStartButton(true);

    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    {/*<IonButtons onClick={closeRun} slot="start">*/}
                    {/*    <IonBackButton defaultHref={`/`} />*/}
                    {/*</IonButtons>*/}
                    <IonTitle size="large">{timer && timer.name}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={closeRun}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {
                    showStartButton &&
                    <IonButton expand="full" onClick={() => startTimerHandler()}>Start</IonButton>
                }
                {
                    showStartButton &&
                    timer && timer.segments && timer.segments.map((item, index) => {
                        return (
                            <IonItem key={item.speech + index}>
                                <IonLabel className="ion-text-wrap">
                                    <h2>{item.speech}</h2>
                                </IonLabel>
                                <IonBadge>{item.duration} seconds</IonBadge>
                            </IonItem>
                        )
                    })
                }
                {
                    !showStartButton &&
                    <IonCard className="ion-text-center ">
                        <h1 className="timerDisplay">{timeDisplay}</h1>
                        <p>{activeSegment && activeSegment.speech}</p>
                    </IonCard>
                }
            </IonContent>
            {
                !showStartButton &&
                <IonFooter>
                    <IonButton color="primary" expand="full" onClick={() => stopTimer()}>Stop Timer</IonButton>
                </IonFooter>
            }
        </IonPage>
    );
};

export default RunTimer;
